import gql from "graphql-tag";

import generalImages from "../fragments/general/generalImages.graphql";
import generalLinks from "../fragments/general/generalLinks.graphql";
import generalFiles from "../fragments/general/generalFiles.graphql";
import generalTextsWYSIWYG from "../fragments/general/generalTextsWYSIWYG.graphql";
import generalSeo from "../fragments/general/generalSeo.graphql";
import generalVideos from "../fragments/general/generalVideos.graphql";
import newsCategory from "../fragments/newsCategory.graphql";

export const GET_NEWS = gql`
    ${generalImages}
    ${generalLinks}
    ${generalFiles}
    ${generalTextsWYSIWYG}
    ${generalSeo}
    ${generalVideos}
    ${newsCategory}

    query ($filter: String, $first: Int) {
        getNewsListing(
            filter: $filter
            sortBy: "date"
            sortOrder: "DESC"
            first: $first
            defaultLanguage: "de"
        ) {
            edges {
                node {
                    id
                    slug
                    date: date_Formatter
                    texts {
                        ...generalTextsWYSIWYG
                    }
                    imageHeader {
                        ...generalImages
                    }
                    imageTeaser {
                        ...generalImages
                    }
                    imageGallery {
                        ...generalImages
                    }
                    links {
                        __typename
                    }
                    fullpath
                    categories {
                        ...newsCategory
                    }
                    links {
                        ...generalLinks
                    }
                    seo {
                        ...generalSeo
                    }
                    files {
                        ...generalFiles
                    }
                    videos {
                        ...generalVideos
                    }
                    author{
                        ... on object_teamMember{
                            salutation
                            firstname
                            lastname
                        }
                    }
                }
            }
        }
    }
`;
